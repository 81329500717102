<template>
    <page-title
        title="社員登録"
        icon="bi-person-fill"
    >
        <router-link :to="{name: 'EmployeeList'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> 社員一覧へ
        </router-link>
    </page-title>

    <section class="section">
        <form>
            <div class="row mb-3">
                <div class="form-group col-md-6">
                    <label>氏名</label>
                    <input type="text" class="form-control" required>
                </div>
                <div class="form-group col-md-3">
                    <label>略称(一文字)</label>
                    <input type="text" class="form-control" required>
                </div>
                <div class="form-group col-md-6 align-self-end pb-2">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                        <label class="form-check-label" for="flexSwitchCheckDefault"><i class="bi bi-camera-fill"></i></label>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>ログインID</label>
                <input type="text" class="form-control" required>
            </div>
            <div class="form-group col-md-6 mb-3">
                <label>パスワード</label>
                <input type="password" class="form-control" required>
                <small class="text-muted">半角英数字8文字以上</small>
            </div>
            <label>権限</label>
            <div class="form-group col-md mb-4">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                    <label class="form-check-label" for="inlineCheckbox1">社員管理</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                    <label class="form-check-label" for="inlineCheckbox2">応援カメラマン管理</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                    <label class="form-check-label" for="inlineCheckbox3">スケジュール管理</label>
                </div>
            </div>

            <div>
                <button class="btn btn-primary btn-lg">
                    <i class="bi bi-plus-lg"></i> 登録
                </button>
            </div>
        </form>

    </section>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'EmployeeAdd',
    components: {
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>
